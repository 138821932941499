import { Contract } from "@/types";
import { FC, useContext } from "react";
import { contractsUIConfig, getContractTradeType } from "@/utils/Contracts";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateOrderFromContractInput, CreateOrderFromContractSchema } from "./validation/createOrderFromContract";
import { useGetBoxtypeValues, postCreateOrderFromContract, useGetCreateOrderValues } from "@/services/Orders";
import { useGetCountries } from "@/services/RegisterCompany";
import { useMutation } from "@tanstack/react-query";
import CreateOrderFromContractStructure from "@/structures/CreateOrderFromContractStructure";
import Checkbox from "@/components/Checkbox/Checkbox";
import Group from "../../atoms/Group";
import OrderModal, { OrderModalContent } from "../Modal/OrderModal";
import UserContext from "@/contexts/UserContext";
import TextField from "@/components/TextField/TextField";
import Dropdown from "@/components/Dropdown/Dropdown";
import CTA from "../../atoms/CTA";
import Stack from "../../atoms/Stack";

interface Props {
  contract: Contract;
  closeNewOrder: () => void;
}

const CreateOrderFromContract: FC<Props> = ({ contract, closeNewOrder }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;
  const contractTradeType = getContractTradeType(contract, currentCompany);
  const { whiteIconName, createOrderModalHeader } = contractsUIConfig(contractTradeType);

  const { data: boxtypeValues } = useGetBoxtypeValues();
  const { data: countries } = useGetCountries();
  const { data: createOrderValues } = useGetCreateOrderValues();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateOrderFromContractInput>({
    resolver: zodResolver(CreateOrderFromContractSchema),
  });

  const { ...registerAmount } = register("amount", { valueAsNumber: true });
  const { ...registerDifferentAddress } = register("differentAddress");
  const { ...registerAddress } = register("address");
  const { ...registerPostCode } = register("postCode");
  const { ...registerPlace } = register("place");

  const { mutateAsync: mutateCreateOrder } = useMutation({
    mutationFn: async (data: CreateOrderFromContractInput) => {
      var newOrderObj = CreateOrderFromContractStructure();
      newOrderObj.companyId = currentCompany.companyId;
      newOrderObj.userId = user.id;
      newOrderObj.amount = data.amount;
      newOrderObj.unit = { id: data.unit.value, unit: data.unit.label };
      newOrderObj.contractId = contract.id;
      newOrderObj.boxtypeId = data.boxtype.value;
      newOrderObj.orderCurrency = { id: 1, value: "nok" };
      newOrderObj.orderNeedsApproval = false;

      if (data.differentAddress) {
        const selectedCountry = countries?.find((country) => country.id === data.countryId!.value);
        if (selectedCountry) {
          newOrderObj.orderAddress = {
            address: data.address!,
            postCode: data.postCode!,
            place: data.place!,
            countryCodeIso3: selectedCountry.iso3,
            countryCodeIso2: selectedCountry.iso2,
            isEu: selectedCountry.isEu,
          };
        }
      }

      await postCreateOrderFromContract(newOrderObj);
    },
    onSuccess: () => {
      closeNewOrder();
    },
  });

  const onSubmit = (data: CreateOrderFromContractInput) => {
    mutateCreateOrder(data);
  };

  return (
    <OrderModal
      open={!!contract}
      onClose={closeNewOrder}
      svgAsset={whiteIconName}
      headerText={t(createOrderModalHeader)}
    >
      <OrderModalContent>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "40px" }}>
          <Group>
            <TextField
              id="amount-order-from-contract"
              label={t("common.amount")}
              type="number"
              errormessage={errors.amount ? t("validation." + errors.amount?.message) : ""}
              {...registerAmount}
            />
            <Controller
              control={control}
              name="unit"
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="order-from-contract-unit"
                  label={t("common.unit")}
                  options={
                    createOrderValues
                      ? createOrderValues.units.map((item) => ({
                          value: item.id,
                          label: t("common.weightUnits." + item.value),
                        }))
                      : []
                  }
                  value={value}
                  onChange={(e: any) => {
                    onChange(e);
                  }}
                  errormessage={errors.unit ? t("validation." + errors.unit?.message) : ""}
                />
              )}
            />
          </Group>
          <Controller
            control={control}
            name="boxtype"
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="order-from-contract-boxtype"
                label={t("common.boxtype")}
                options={
                  boxtypeValues
                    ? boxtypeValues.map((box) => ({
                        value: box.id,
                        label: t("common.boxtypes." + box.name),
                        boxtypeObj: box,
                      }))
                    : []
                }
                value={value}
                onChange={(e: any) => {
                  onChange(e);
                }}
                errormessage={errors.boxtype ? t("validation." + errors.boxtype?.message) : ""}
              />
            )}
          />

          <Checkbox label={t("commonOrders.differentAddress")} {...registerDifferentAddress} />
          <Group>
            <Stack>
              <TextField
                id="address-order-from-contract"
                label={t("commonOrders.orderAddress")}
                errormessage={errors.address ? t("validation." + errors.address.message) : ""}
                disabled={!watch("differentAddress")}
                {...registerAddress}
              />
              <Controller
                control={control}
                name="countryId"
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    id="country-order-from-contract"
                    label={t("common.country")}
                    options={
                      countries
                        ? countries.map((country) => ({
                            value: country.id,
                            label: country.name,
                          }))
                        : []
                    }
                    isDisabled={!watch("differentAddress")}
                    value={value}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    errormessage={errors.countryId ? t("validation." + errors.countryId.message) : ""}
                  />
                )}
              />
            </Stack>
            <Stack>
              <TextField
                id="postCode-order-from-contract"
                label={t("commonOrders.postCode")}
                errormessage={errors.postCode ? t("validation." + errors.postCode.message) : ""}
                disabled={!watch("differentAddress")}
                {...registerPostCode}
              />
              <TextField
                id="postPlace"
                label={t("commonOrders.place")}
                errormessage={errors.place ? t("validation." + errors.place.message) : ""}
                disabled={!watch("differentAddress")}
                {...registerPlace}
              />
            </Stack>
          </Group>

          <Group>
            <CTA id="submit-order-from-contract" intent="secondary" size="lg" type="submit" onClick={closeNewOrder}>
              {t("common.cancel")}
            </CTA>
            <CTA id="submit-order-from-contract" intent="primary" size="lg" type="submit">
              {t("commonOrders.createOrder")}
            </CTA>
          </Group>
        </form>
      </OrderModalContent>
    </OrderModal>
  );
};

export default CreateOrderFromContract;
