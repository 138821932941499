import { FC } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import Dropdown from "@/components/Dropdown/Dropdown";
import Checkbox from "@/components/Checkbox/Checkbox";
import TextField from "@/components/TextField/TextField";
import { CSSTransition } from "react-transition-group";
import Spinner from "@/components/Spinner/Spinner";
import Space from "@/components/_Reviver/atoms/Space";
import CTA from "@/components/_Reviver/atoms/CTA";
import Divider from "@/components/_Reviver/atoms/Divider";
import MessageBar from "@/components/MessageBar/MessageBar";

interface Props {
  isAddressCBDrawerChecked: any;
  onClickDifferentAddressCBDrawerHandler: any;
  addressDrawerNodeRef: any;
  addressDrawer: any;
  setAddressDrawer: any;
  addressValidationErrors: any;
  countryOptionsDrawer: any;
  setSelectedCountryDrawer: any;
  selectedCountryDrawer: any;
  postCodeDrawer: any;
  setPostCodeDrawer: any;
  placeDrawer: any;
  setPlaceDrawer: any;
  validateDeliveryFromDrawer: any;
  isLoadingNewPriceDrawer: any;
  validateAddressMessage: any;
}

const OrderDetailsAddress: FC<Props> = (props) => {
  const {
    isAddressCBDrawerChecked,
    onClickDifferentAddressCBDrawerHandler,
    addressDrawerNodeRef,
    addressDrawer,
    setAddressDrawer,
    addressValidationErrors,
    countryOptionsDrawer,
    setSelectedCountryDrawer,
    selectedCountryDrawer,
    postCodeDrawer,
    setPostCodeDrawer,
    placeDrawer,
    setPlaceDrawer,
    validateDeliveryFromDrawer,
    isLoadingNewPriceDrawer,
    validateAddressMessage,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Grid.Row>
        <Grid.Col span={12}>
          <Checkbox
            label={t("commonOrders.differentAddress")}
            checked={isAddressCBDrawerChecked}
            onChange={(e: any) => onClickDifferentAddressCBDrawerHandler(e)}
          />
        </Grid.Col>
        <Space height="10px" />
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={12}>
          <CSSTransition
            in={isAddressCBDrawerChecked}
            timeout={{
              enter: 1000,
              exit: 300,
            }}
            mountOnEnter
            unmountOnExit
            classNames="trade-address-transition-drawer"
            nodeRef={addressDrawerNodeRef}
          >
            <div ref={addressDrawerNodeRef}>
              <Grid compact={true}>
                <Grid.Col span={6}>
                  <TextField
                    id="address-drawer"
                    label={t("commonOrders.orderAddress")}
                    value={addressDrawer}
                    onChange={(e: any) => setAddressDrawer(e.target.value)}
                    errormessage={addressValidationErrors.address}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Dropdown
                    id="country-drawer"
                    label={t("common.country")}
                    options={countryOptionsDrawer}
                    onChange={(e) => setSelectedCountryDrawer(e)}
                    value={selectedCountryDrawer}
                    menuPlacement="top"
                    errormessage={addressValidationErrors.country}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextField
                    id="postCode-drawer"
                    type="number"
                    label={t("commonOrders.postCode")}
                    value={postCodeDrawer}
                    onChange={(e: any) => setPostCodeDrawer(e.target.value)}
                    errormessage={addressValidationErrors.postCode}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextField
                    id="place-drawer"
                    label={t("commonOrders.place")}
                    value={placeDrawer}
                    onChange={(e: any) => setPlaceDrawer(e.target.value)}
                    errormessage={addressValidationErrors.place}
                  />
                </Grid.Col>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <CTA
                      id="checkAddress"
                      onClick={() => validateDeliveryFromDrawer()}
                      intent="secondary"
                      fullWidth={true}
                    >
                      {t("commonOrders.calculatePrice")}
                    </CTA>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Col span={9}>
                  <Spinner loading={isLoadingNewPriceDrawer} inline={true} />
                  {!isLoadingNewPriceDrawer && (
                    <MessageBar type={MessageBar.type.Positive} message={validateAddressMessage} />
                  )}
                </Grid.Col>
              </Grid>
            </div>
          </CSSTransition>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};
export default OrderDetailsAddress;
