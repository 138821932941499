import React, { useContext, useState, useEffect } from "react";
import Grid from "@/components/Grid/Grid";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import UserContext from "@/contexts/UserContext";
import Pagination from "@/components/Pagination/Pagination";
import { ErrorDialogContext } from "@/contexts/ErrorDialogContext";
import { ContractsHeader } from "@/components/_Reviver/molecules/SubHeader";

import styles from "./styles.module.css";
import CreateContractCta from "../../molecules/CreateContractCta";
import ContractCard from "../../organisms/ContractCard";
import CreateContract from "../../organisms/CreateContract";
import { useGetContracts } from "@/services/Contracts";
import { Contract } from "@/types";
import EditContract from "../../organisms/EditContract";
import ContractsTable from "../../organisms/StatusTable/ContractsTable";
import Heading from "../../atoms/Heading";
import CreateOrderFromContract from "../../organisms/CreateOrderFromContract";

interface Props {
  setUserContext: any;
}

export default function Contracts(props: Props) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const errorDialogContext = useContext(ErrorDialogContext);
  const { setErrorDialogOptions = () => {}, showErrorDialog = () => {} } = errorDialogContext || {};

  const [editContract, setEditContract] = useState<Contract | undefined>(undefined);
  const [newOrder, setNewOrder] = useState<Contract | null>(null);
  const [contractWizardIsOpen, setContractWizardIsOpen] = useState(false);

  const {
    data: contracts,
    isError: isErrorFetchingContracts,
    error: fetchingContractsError,
    isLoading: isFetchingContracts,
  } = useGetContracts(currentCompany.companyId);

  useEffect(() => {
    if (isErrorFetchingContracts) {
      setErrorDialogOptions({ message: "Fetching contracts failed", error: fetchingContractsError as Error });
      showErrorDialog(true);
    }
  }, [isErrorFetchingContracts]);

  return (
    <>
      <Helmet>
        <title>Reviver - {t("contracts.contracts")}</title>
        <meta name="description" content="Reviver - {t('contracts.contracts')}" />
      </Helmet>

      <CreateContract isOpen={contractWizardIsOpen} close={() => setContractWizardIsOpen(false)} />

      <div className={styles.contractsRoot}>
        {editContract ? (
          <>
            <EditContract
              contract={editContract}
              backToContractsList={() => setEditContract(undefined)}
              openNewOrder={() => setNewOrder(editContract)}
            />
            <div className={styles.contractsTable}>
              <header className={styles.contractsTableHeader}>
                <Heading order={5} strong={true}>
                  {editContract.buyOrdersOnContract.length} {t("contracts.ordersOnContract")}
                </Heading>
              </header>
              <ContractsTable contract={editContract} />
            </div>
          </>
        ) : (
          <>
            <ContractsHeader
              companyName={currentCompany.companyName}
              totalContracts={contracts?.length}
              sortOptions={[]}
            />
            <div className={styles.activeContracts}>
              <div className={styles.activeContractsInner}>
                <CreateContractCta onClick={() => setContractWizardIsOpen(true)} />
                {isFetchingContracts && <div>Loading...</div>}
                {contracts?.map((contract, i) => (
                  <ContractCard
                    contract={contract}
                    showDetails={() => setEditContract(contract)}
                    openNewOrder={() => setNewOrder(contract)}
                    key={i}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Col span={12} className="flex right result-piginator">
              <Pagination numberOfPages={0} neighbours={2} onClick={() => {}} reset={!!currentCompany} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        {newOrder && <CreateOrderFromContract contract={newOrder} closeNewOrder={() => setNewOrder(null)} />}
      </div>
    </>
  );
}
