import React, { FC, useContext } from "react";
import moment, { isDate } from "moment";
import styles from "./styles.module.css";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import Text from "../../atoms/Text";
import Stack from "../../atoms/Stack";
import Heading from "../../atoms/Heading";
import Group from "../../atoms/Group";
import CTA from "../../atoms/CTA";
import { useTranslation } from "react-i18next";
import { Contract } from "@/types";
import UserContext from "@/contexts/UserContext";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import {
  contractsUIConfig,
  getContractCardDetails,
  getContractTradeType,
  getContractProgressPercent,
} from "@/utils/Contracts";
import Progress from "../../atoms/Progress";

interface Props {
  contract: Contract;
  showDetails: () => void;
  openNewOrder: () => void;
}

const ContractCard: FC<Props> = ({ contract, showDetails, openNewOrder }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const contractTradeType = getContractTradeType(contract, currentCompany);
  const { iconName, companyIcon, partnerIcon } = contractsUIConfig(contractTradeType);
  const { sellerName, buyerName, allApproved, createdDate } = getContractCardDetails(contract);

  return (
    <div className={styles.contractCardRoot}>
      <SVGAsset name={iconName} width="36" height="36" className={styles.icon} />
      <Text size="xs" className={styles.dateCreated} color="secondary">
        {createdDate && moment(createdDate).local().format("DD.MM.YYYY")}
      </Text>
      <Stack gap="17px" grow={true}>
        <Stack gap="2px">
          <Text span={true} size="xs" tt="uppercase" color="tertiary">
            {contract.name}
          </Text>
          <Heading order={4} styleOrder={5} strong={true}>
            {contract.contractProduct.companySpecificProduct.companySpecificProductName}
          </Heading>
        </Stack>
        <Stack gap={0} className={styles[contractTradeType]}>
          <Text size="md" strong={true}>
            <SVGAsset name={companyIcon} width="12" height="12" className={styles.tradeIcon} />
            {contractTradeType === ContractTradeTypeEnum.SELL ? sellerName : buyerName}
          </Text>
          <Text size="md" strong={true}>
            <SVGAsset name={partnerIcon} width="12" height="12" className={styles.tradeIcon} />
            {contractTradeType === ContractTradeTypeEnum.SELL ? buyerName : sellerName}
          </Text>
        </Stack>
        {!allApproved ? (
          <Progress percent={getContractProgressPercent(contract)} contractTradeType={contractTradeType} />
        ) : (
          <ApprovalWarning />
        )}
        <Group>
          <CTA intent="primary" size="sm" onClick={showDetails}>
            {t("common.details")}
          </CTA>
          <CTA intent="secondary" size="sm" onClick={openNewOrder}>
            {t("commonOrders.newOrder")}
          </CTA>
        </Group>
      </Stack>
    </div>
  );
};

export const ApprovalWarning: FC = () => (
  <p style={{ position: "relative", margin: 0 }}>
    <SVGAsset name="yellow-exclamation" className={styles.approvalWarning} />
    <ApprovalWarningText />
  </p>
);

export const ApprovalWarningText: FC = () => (
  <Text span={true} color="warning" size="xs">
    Kontraktet må signeres av begge parter
  </Text>
);

export default ContractCard;
