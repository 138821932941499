import { CreateOrderFromContract } from "@/types";

function CreateOrderFromContractStructure(): CreateOrderFromContract {
  let createOrderFromContractObject = {
    companyId: 0,
    userId: 0,
    amount: 0,
    unit: { id: 0, unit: "" },
    orderAddress: null,
    contractId: 0,
    boxtypeId: 0,
    orderCurrency: { id: 0, value: "" },
    orderNeedsApproval: false
  };

  return createOrderFromContractObject;
}

export default CreateOrderFromContractStructure;