import { CreateOrder } from "@/types";

export default function getUpdateOrderStructure(order: any, user: any) {
  let updateObject: CreateOrder = {
    companyId: order.company.id,
    userId: user.id,
    orderType: order.orderType.id,
    salesItem: {
      id: order.salesItem.id,
      value: order.salesItem.salesItem,
    },
    amount: order.amount.toString(),
    minimumAmount: order.minimumAmount.toString(),
    unit: order.unit,
    condition: {
      id: order.condition.id,
      value: order.condition.condition,
    },
    price: order.price[order.orderCurrency],
    priceUnit: order.priceUnit,
    maxPrice: order.maxPrice ? order.maxPrice[order.orderCurrency] : "",
    minPrice: order.minPrice ? order.minPrice[order.orderCurrency] : "",
    orderCurrency: {
      id: order.orderCurrency.id,
      value: order.orderCurrency,
    },
    validTo: order.validTo,
    validFrom: order.validFrom,
    deliveryStart: order.deliveryStart,
    deliveryEnd: order.deliveryEnd,
    description: order.description,
    images: order.images,
    catchDate: order.catchDate,
    expirationDate: order.expirationDate,
    packingDate: order.packingDate,
    transportFrom: order.transportFrom,
    promoted: order.promoted,
    priceAdjustPercentage: order.priceAdjustPercentage,
    autoSalesOrder: order.autoSalesOrder,
    companiesToTradeWith: order.companiesToTradeWith,
    lastDeliveryDate: order.lastDeliveryDate,
    packaging: {
      boxtype: order.packaging.boxtype.id,
      numberOfBoxes: order.packaging.numberOfBoxes,
      amountInEachBox: order.packaging.amountInEachBox,
      palletPlaces: order.packaging.palletPlaces,
      isSealed: order.packaging.isSealed,
    },
    amountDeviation: order.amountDeviation,
    type: null,
    format: null,
    size: null,
    color: null,
    orderAddress: null,
    externalOrderid: null,
    contractId: null,
  };
  // These are not required in create order, need to nullcheck
  if (order.type) {
    updateObject = {
      ...updateObject,
      type: {
        id: order.type.id,
        value: order.type.type,
      },
    };
  }

  if (order.color) {
    updateObject = {
      ...updateObject,
      color: {
        id: order.color.id,
        value: order.color.color,
      },
    };
  }

  if (order.size) {
    updateObject = {
      ...updateObject,
      size: {
        id: order.size.id,
        value: order.size.size,
      },
    };
  }

  if (order.format) {
    updateObject = {
      ...updateObject,
      format: {
        id: order.format.id,
        value: order.format.format,
      },
    };
  }

  return updateObject;
}
