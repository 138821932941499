import { Contract, CreateContractDto } from "@/types";
import { axios, APIBASE_REVIVERCONTRACTS } from "./ApiConfig";
import { useQuery } from "@tanstack/react-query";

export const getContracts = async (companyId: any) => axios.get(`${APIBASE_REVIVERCONTRACTS}api/search/all`, {
  params: {
    companyId
  }
});

export const useGetContracts = (companyId: number, options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<Contract[]>({
    queryKey: ['getContracts', companyId],
    queryFn: async () => {
      const result = await getContracts(companyId);
      return result.data;
    },
    ...queryOptions,
  });
};

export const postCreateContract = async (contract: CreateContractDto, companyId: any) =>
  axios.post(`${APIBASE_REVIVERCONTRACTS}api/contracts/create`, contract, {
    params: {
      companyId
    }
  });