import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import Grid from "../../../Grid/Grid";
import TextField from "../../../TextField/TextField";
import CheckBox from "../../../Checkbox/Checkbox";
import UserContext from "../../../../contexts/UserContext";
import Dropdown from "../../../Dropdown/Dropdown";
import Spinner from "../../../Spinner/Spinner";
import Modal, { ModalCTAs, ModalContent } from "../../organisms/Modal";
import MessageBar from "../../../MessageBar/MessageBar";
import MembershipTypeEnum from "../../../../constants/enums/MembershipTypeEnum";

import { ErrorDialogContext } from "../../../../contexts/ErrorDialogContext";
import { get, capitalize } from "lodash";
import { getCompanyById, getTransportCompanies } from "../../../../services/Company";
import {
  getLanguages,
  getCompanyTypes,
  getCountries,
  getTimeZones,
  getAddressTypes,
  getMembershipTypes,
} from "../../../../services/RegisterCompany";
import { getCreateOrderValues } from "../../../../services/Orders";
import {
  updateCompany,
  postNewAddress,
  deleteAddress,
  updateAddress,
  postNewBankAccount,
  deleteBankAccount,
  updateBankAccount,
} from "../../../../services/Settings";
import { useTranslation } from "react-i18next";
import { getCompaniesByUserId } from "../../../../services/Login";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";
import {
  companySettingsSchema,
  addressSettingsSchema,
  bankAccountSettingsSchema,
} from "./validation/CompanySettingsValidationSchema";

import PhoneField from "../../../PhoneInput/PhoneInput";
import CTA from "../../atoms/CTA";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import Stack from "../../atoms/Stack";
import Divider from "../../atoms/Divider";
import Space from "../../atoms/Space";
import Group from "../../atoms/Group";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import {
  Address,
  AddressType,
  BankAccount,
  Company,
  CompanyType,
  Country,
  CountryOption,
  DropdownOption,
  DropdownStringOption,
  Language,
  LanguageOption,
  MembershipType,
  OrderValue,
  ValidationErrors,
} from "types";
import { isAdminUser } from "@/utils/User";
import BankAccountTable from "../../organisms/SettingsTable/BankAccountTable";
import CompanyAddressTable from "../../organisms/SettingsTable/CompanyAddressTable";

interface Props {
  setUserContext: (userContext: any) => void;
  changeLocale: (locale: any, i18n: any, timeZone: any) => void;
}

export default function CompanySettings(props: Props) {
  const { t, i18n } = useTranslation();
  const { setUserContext, changeLocale } = props;

  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};
  const userContext = useContext(UserContext);
  const { currentCompany, user } = userContext;

  const [loadingSave, setLoadingSave] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption>();
  const [companyTypesOptions, setCompanyTypesOptions] = useState<DropdownStringOption[]>([]);
  const [selectedCompanyType, setSelectedCompanyType] = useState<DropdownStringOption>();
  const [transportOptions, setTransportOptions] = useState<DropdownOption[]>([]);
  const [selectedTransportCompany, setSelectedTransportCompany] = useState<DropdownOption>();
  const [timeZoneOptions, setTimeZoneOptions] = useState<DropdownStringOption[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<DropdownStringOption>();
  const [currencyOptions, setCurrencyOptions] = useState<DropdownOption[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownOption>();
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [membershipTypeOptions, setMembershipTypeOptions] = useState<DropdownStringOption[]>([]);
  const [selectedMembershipType, setSelectedMembershipType] = useState<DropdownStringOption>();

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [validationErrorsAddress, setValidationErrorsAddress] = useState<ValidationErrors>({});
  const [validationErrorsBankAccount, setValidationErrorsBankAccount] = useState<ValidationErrors>({});

  // EDIT/ADD COMPANY ADDRESS
  const [isAddEditAddressOpen, setIsAddEditAddressOpen] = useState<boolean>(false);
  const [isNewAddress, setIsNewAddress] = useState<boolean>(false);
  const [addressToEdit, setAddressToEdit] = useState(0);
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [place, setPlace] = useState("");
  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | undefined>(undefined);
  const [addressTypeOptions, setAddressTypeOptions] = useState<DropdownStringOption[]>([]);
  const [selectedAddressType, setSelectedAddressType] = useState<DropdownStringOption>();
  const [messageBarMessage, setMessageBarMessage] = useState<string | undefined>(undefined);
  const [messageBarType, setMessageBarType] = useState<string | undefined>();

  // EDIT/ADD BANK ACCOUNTS
  const [isAddEditBankAccountOpen, setIsAddEditBankAccountOpen] = useState(false);
  const [isNewBankAccount, setIsNewBankAccount] = useState(false);
  const [bankAccountToEdit, setBankAccountToEdit] = useState(0);
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNr, setBankAccountNr] = useState("");
  const [bankAccountIban, setBankAccountIban] = useState("");

  const resetDeleteAddressWarning = () => {
    setTimeout(() => {
      setMessageBarMessage(undefined);
      setMessageBarType(undefined);
    }, 6000);
  };

  useEffect(() => {
    let loaded = false;

    async function asyncGetCompany() {
      const response = await getCompanyById(currentCompany.companyId);
      setCompany(response.data);
    }

    async function loadLanguages() {
      const response = await getLanguages();
      let languageOptions = response.data;

      // Language dropdown
      const _languageOptions = languageOptions.languages.map((language: Language) => ({
        value: language.id,
        label: language.name,
        languageCode: language.languageCode,
      }));
      setLanguageOptions(_languageOptions);
    }

    // Company types
    async function asyncGetCompanyTypes() {
      const companyTypesData = await getCompanyTypes();

      const _companyTypesOptions = companyTypesData.data.companyTypes
        .filter((type: CompanyType) => type !== "TRANSPORT")
        .map((type: CompanyType) => ({
          value: type,
          label: t("registerCompany.companyTypes." + type),
        }));
      setCompanyTypesOptions(_companyTypesOptions);
    }

    // Transport company
    async function asyncGetTransportCompanies() {
      const transport = await getTransportCompanies();

      const _transportOptions = transport.data.companies.map((company: Company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setTransportOptions(_transportOptions);
    }

    // Country
    async function asyncGetCountries() {
      const countries = await getCountries();

      const _countryOptions = countries.data.map((country: Country) => ({
        value: country.id,
        label: country.name,
        iso3Code: country.iso3,
        iso2Code: country.iso2,
      }));
      setCountryOptions(_countryOptions);
    }

    // Timezones
    async function asyncGetTimeZones() {
      const zones = await getTimeZones();

      const _zones = zones.data.timeZone.map((zone: string) => ({
        value: zone,
        label: zone,
      }));
      setTimeZoneOptions(_zones);
    }

    // Address type
    async function asyncGetAddressTypes() {
      const types = await getAddressTypes();

      const _types = types.data.addressTypes.map((type: AddressType) => ({
        value: type,
        label: t("registerCompany.addressTypes." + type),
      }));
      setAddressTypeOptions(_types);
    }

    // Currency
    async function asyncGetCurrency() {
      const currencies = await getCreateOrderValues();
      const _currencies = currencies.data.currencies.map((currency: OrderValue) => ({
        value: currency.id,
        label: currency.value.toUpperCase(),
      }));
      setCurrencyOptions(_currencies);
    }

    // Membership
    async function asyncGetMembershipTypes() {
      const memberships = await getMembershipTypes();

      const _memberships = memberships.data.map((membership: MembershipType) => ({
        value: membership,
        label: capitalize(membership),
      }));
      setMembershipTypeOptions(_memberships);
    }

    if (!loaded) {
      loadLanguages();
      asyncGetCompany();
      asyncGetCompanyTypes();
      asyncGetTransportCompanies();
      asyncGetCountries();
      asyncGetTimeZones();
      asyncGetAddressTypes();
      asyncGetCurrency();
      asyncGetMembershipTypes();
    }

    return () => {
      loaded = true;
    };
  }, [currentCompany.companyId, t]);

  useEffect(() => {
    // prefill language
    const _selectedLanguage = languageOptions.find((option) => option.value === company?.language.id);
    setSelectedLanguage(_selectedLanguage);

    // prefill companyType
    const _selectedCompanyType = companyTypesOptions.find((option) => option.value === company?.companyType);
    setSelectedCompanyType(_selectedCompanyType);

    // prefill transport company
    const _transportCompany = transportOptions.find(
      (option) => option.value === get(company, "preferredTransportCompanyId")
    );
    setSelectedTransportCompany(_transportCompany);

    // prefill timeZone
    const _zone = timeZoneOptions.find((option) => option.value === company?.timeZone);

    setSelectedTimeZone(_zone);

    // prefill currency
    const _currency = currencyOptions.find((option) => option.label === company?.defaultCurrency);
    setSelectedCurrency(_currency);

    // prefill membership
    const _membership = membershipTypeOptions.find((option) => option.value === company?.membership);
    setSelectedMembershipType(_membership);
  }, [
    languageOptions,
    selectedLanguage,
    company,
    companyTypesOptions,
    transportOptions,
    timeZoneOptions,
    currencyOptions,
    membershipTypeOptions,
  ]);

  function onChange(change: any) {
    setCompany({
      ...company,
      ...change,
    });
  }

  function onChangeLanguage(e: any) {
    setSelectedLanguage(e);
    const language = e;
    onChange({
      language: {
        id: language.value,
        name: language.label,
        languageCode: language.languageCode,
      },
    });
  }

  function onChangeCompanyType(e: any) {
    setSelectedCompanyType(e);
    const companyType = e;
    onChange({
      companyType: companyType.value,
    });
  }

  function onChangeTransportCompany(e: any) {
    setSelectedTransportCompany(e);
    const transportCompany = e;
    if (transportCompany === null) {
      onChange({
        preferredTransportCompanyId: null,
      });
    } else {
      onChange({
        preferredTransportCompanyId: transportCompany.value,
      });
    }
  }

  function onChangeTimeZone(e: any) {
    setSelectedTimeZone(e);
    const timeZone = e.value;
    onChange({
      timeZone: timeZone,
    });
  }

  function onChangeCurrency(e: any) {
    setSelectedCurrency(e);
    const currency = e;
    onChange({
      defaultCurrency: currency.label,
    });
  }

  function onChangeMembershipType(e: any) {
    setSelectedMembershipType(e);
    const membership = e.value;
    onChange({
      membership: membership,
    });
  }

  const saveHandler = async () => {
    try {
      setLoadingSave(true);
      const inputChecker = validateFieldsHelper(companySettingsSchema(t), company);
      setValidationErrors(inputChecker as ValidationErrors);

      if (Object.keys(inputChecker || {}).length === 0) {
        await updateCompany(company);
        setValidationErrors({});

        // Load userContext to instantly use new settings
        let companies, currentCompany;
        if (userContext.currentCompany.companyId) {
          const response = await getCompaniesByUserId(userContext.user.id, userContext.currentCompany.companyId);
          companies = response.data;
          currentCompany =
            companies.companies.find((company: any) => company.companyId === userContext.currentCompany.companyId) ||
            companies.companies[0];
        }
        setUserContext({
          ...userContext,
          companies,
          currentCompany,
        });
        changeLocale(user.language.languageCode, i18n, currentCompany.companyTimeZone);
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Update company feilet.", error: error as Error });
      showErrorDialog(true);
    }
    setLoadingSave(false);
  };

  async function RefreshCompany(companyId: number) {
    const response = await getCompanyById(companyId);
    setCompany(response.data);
  }

  function resetEditCompany() {
    setMissingFields([]);
    setAddressToEdit(0);
    setAddress("");
    setPostCode("");
    setPlace("");
    setSelectedCountry(undefined);
    setSelectedAddressType(undefined);
  }

  const openAddAddressHandler = () => {
    resetEditCompany();
    setIsNewAddress(true);
    setIsAddEditAddressOpen(true);
  };

  function closeEditAddress() {
    setIsAddEditAddressOpen(false);
    setValidationErrorsAddress({});
  }

  const openEditAddressHandler = (index: number, array: any[]) => {
    resetEditCompany();
    setIsNewAddress(false);

    const addressToEdit = array.find((address) => address === array[index]);
    setAddressToEdit(addressToEdit.id);
    setAddress(addressToEdit.address);
    setPostCode(addressToEdit.postCode);
    setPlace(addressToEdit.place);
    const _country = countryOptions.find((option: any) => option.value === addressToEdit.country.id);
    setSelectedCountry(_country);
    const _addressType = addressTypeOptions.find((option) => option.value === addressToEdit.addressType);
    setSelectedAddressType(_addressType);

    setIsAddEditAddressOpen(true);
  };

  const addEditAddressHandler = async () => {
    if (isNewAddress) {
      if (
        selectedAddressType?.value === "SHIPPING" &&
        company?.companyAddress &&
        company?.companyAddress.some((address) => address.addressType === "SHIPPING")
      ) {
        setMissingFields([t("validation.onlyOneShippingAddress")]);
        return;
      }

      try {
        const inputCheckerAddress = validateFieldsHelper(addressSettingsSchema(t), {
          address: address,
          postCode: postCode,
          place: place,
          country: selectedCountry?.label,
          addressType: selectedAddressType?.value,
        });
        setValidationErrorsAddress(inputCheckerAddress as any);

        if (Object.keys(inputCheckerAddress || {}).length === 0) {
          await postNewAddress(
            company?.id,
            address,
            postCode,
            place,
            selectedCountry?.value,
            selectedAddressType?.value
          );

          setValidationErrorsAddress({});
          company && RefreshCompany(company.id);
          setIsAddEditAddressOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({ message: "Adding new address failed.", error: error as Error });
        showErrorDialog(true);
      }
    } else {
      try {
        const inputCheckerAddress = validateFieldsHelper(addressSettingsSchema(t), {
          address: address,
          postCode: postCode,
          place: place,
          country: selectedCountry?.label,
          addressType: selectedAddressType?.value,
        });
        setValidationErrorsAddress(inputCheckerAddress as any);

        if (Object.keys(inputCheckerAddress || {}).length === 0) {
          const country = {
            id: selectedCountry?.value,
            name: selectedCountry?.label,
            iso3: selectedCountry?.iso3Code,
            iso2: selectedCountry?.iso2Code,
          };
          await updateAddress(
            company?.id,
            addressToEdit,
            address,
            postCode,
            place,
            country,
            selectedAddressType?.value
          );

          setValidationErrorsAddress({});
          company && RefreshCompany(company.id);
          setIsAddEditAddressOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({ message: "Editing address failed.", error: error as Error });
        showErrorDialog(true);
      }
    }
  };

  async function asyncDeleteAddress(index: number, array: any[]) {
    try {
      const addressToDelete = array.find((address: Address) => address === array[index]);
      if (addressToDelete) {
        if (addressToDelete.addressType !== "SHIPPING") {
          await deleteAddress(company?.id, addressToDelete.id);
        } else {
          setMessageBarMessage(t("settings.deleteCompanyAddressWarning"));
          setMessageBarType("Warning");
          resetDeleteAddressWarning();
        }
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Delete address failed.", error: error as Error });
      showErrorDialog(true);
    }

    company?.id && RefreshCompany(company.id);
  }

  function resetEditBankAccount() {
    setMissingFields([]);
    setBankAccountToEdit(0);
    setBankAccountName("");
    setBankAccountNr("");
    setBankAccountIban("");
  }

  const openAddBankAccountHandler = () => {
    resetEditBankAccount();
    setIsNewBankAccount(true);
    setIsAddEditBankAccountOpen(true);
  };

  function closeEditBankAccount() {
    setIsAddEditBankAccountOpen(false);
    setValidationErrorsBankAccount({});
  }

  const openEditBankAccountHandler = (index: number, array: any[]) => {
    resetEditBankAccount();
    setIsNewBankAccount(false);

    const bankAccountToEdit = array.find((account: BankAccount) => account === array[index]);
    setBankAccountToEdit(bankAccountToEdit.id);
    setBankAccountName(bankAccountToEdit.name);
    setBankAccountNr(bankAccountToEdit.accountNo);
    setBankAccountIban(bankAccountToEdit.iban);

    setIsAddEditBankAccountOpen(true);
  };

  const addEditBankAccountHandler = async () => {
    if (isNewBankAccount) {
      try {
        const inputCheckerBank: any = validateFieldsHelper(bankAccountSettingsSchema(t), {
          name: bankAccountName,
          accountNo: bankAccountNr,
          iban: bankAccountIban,
        });
        setValidationErrorsBankAccount(inputCheckerBank);

        if (Object.keys(inputCheckerBank).length === 0) {
          await postNewBankAccount(company?.id, bankAccountName, bankAccountNr, bankAccountIban);
          setValidationErrorsBankAccount({});
          company?.id && RefreshCompany(company.id);
          setIsAddEditBankAccountOpen(false);
        }
      } catch (error) {
        console.log(error);
        setErrorDialogOptions({
          message: "Adding new bank account failed.",
          error: error as Error,
        });
        showErrorDialog(true);
      }
    } else {
      try {
        const inputCheckerBank: any = validateFieldsHelper(bankAccountSettingsSchema(t), {
          name: bankAccountName,
          accountNo: bankAccountNr,
          iban: bankAccountIban,
        });
        setValidationErrorsBankAccount(inputCheckerBank);

        if (Object.keys(inputCheckerBank).length === 0) {
          await updateBankAccount(company?.id, bankAccountToEdit, bankAccountName, bankAccountNr, bankAccountIban);
          setValidationErrorsBankAccount({});
          company?.id && RefreshCompany(company.id);
          setIsAddEditBankAccountOpen(false);
        }
      } catch (error) {
        setErrorDialogOptions({
          message: "Editing bank account failed.",
          error: error as Error,
        });
        showErrorDialog(true);
      }
    }
  };

  async function asyncDeleteBankAccount(index: number, array: any[]) {
    try {
      const bankAccountToDelete = array.find((account) => account === array[index]);
      if (bankAccountToDelete) await deleteBankAccount(company?.id, bankAccountToDelete.id);
    } catch (error) {
      setErrorDialogOptions({ message: "Delete bank account failed.", error: error as Error });
      showErrorDialog(true);
    }

    company?.id && RefreshCompany(company.id);
  }
  //#endregion

  const isAdmin = isAdminUser(user, currentCompany);

  return (
    <>
      <Heading order={2} styleOrder={4} tt="uppercase">
        {t("common.company")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="technical" tt="uppercase">
                {t("common.details")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("common.company")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={8}>
              <TextField
                id="company-name"
                label={t("common.companyName")}
                value={get(company, "companyName", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ companyName: e.target.value })}
                errormessage={validationErrors?.companyName}
                disabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Dropdown
                id="company-type"
                label={t("common.companyType")}
                options={companyTypesOptions}
                onChange={onChangeCompanyType}
                value={selectedCompanyType}
                errormessage={validationErrors?.companyType}
                isDisabled
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={4}>
              <TextField
                id="vat-number"
                label={t("common.vatNumber")}
                value={get(company, "vatNumber", "") || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ vatNumber: e.target.value })}
                errormessage={validationErrors?.vatNumber}
                placeholder="000 000 000"
                disabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextField
                id="lei-number"
                label={t("common.leiNumber")}
                value={get(company, "leiNumber", "") || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ leiNumber: e.target.value })}
                errormessage={validationErrors?.leiNumber}
                placeholder="0000 00 000000000000 00"
                disabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextField
                id="exportlicense"
                label={t("common.exportlicense")}
                value={get(company, "exportLicense", "") || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ exportLicense: e.target.value })}
                errormessage={validationErrors?.exportLicense}
                placeholder="0A000"
                disabled={!isAdmin}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("settings.contact")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <TextField
                id="email"
                label={t("common.email")}
                value={get(company, "email", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ email: e.target.value })}
                errormessage={validationErrors?.email}
                disabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PhoneField
                id="phone"
                value={get(company, "phone")}
                placeholder={t("common.phone")}
                onChange={(e) => onChange({ phone: e })}
                errormessage={validationErrors?.phone}
                disabled={!isAdmin}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("common.details")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={4}>
              <Dropdown
                id="language"
                label={t("common.language")}
                options={languageOptions}
                onChange={onChangeLanguage}
                value={selectedLanguage}
                errormessage={validationErrors?.language}
                isDisabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Dropdown
                id="timezone"
                label={t("common.timeZone")}
                options={timeZoneOptions}
                onChange={onChangeTimeZone}
                value={selectedTimeZone}
                errormessage={validationErrors?.timeZone}
                isDisabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Dropdown
                id="currency"
                label={t("common.currency")}
                options={currencyOptions}
                onChange={onChangeCurrency}
                value={selectedCurrency}
                errormessage={validationErrors?.currency}
                isDisabled={!isAdmin}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("settings.transport")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="transport-company"
                label={t("settings.defaultTransportTitle")}
                isDisabled={!isAdmin || !!company?.withoutTransport}
                options={transportOptions}
                onChange={onChangeTransportCompany}
                value={selectedTransportCompany}
                isClearable
                clearValue={() => onChangeTransportCompany(null)}
              />
              <Text size="xs" color="secondary">
                {t("settings.defaultTransportDisclaimer")}
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack gap="16px">
                <Space height="10px" />
                <CheckBox
                  id="handle-transport"
                  label={t("settings.handleTransport")}
                  checked={!!company?.withoutTransport}
                  onChange={(e: any) => onChange({ withoutTransport: !!e.checked })}
                  disabled={!isAdmin}
                />
                <CheckBox
                  id="allowCollectiveInvoice"
                  label={t("common.allowCollectiveInvoice")}
                  checked={!company?.allowCollectiveInvoice}
                  onChange={(e: any) => onChange({ allowCollectiveInvoice: !e.checked })}
                  disabled={!isAdmin}
                />
              </Stack>
            </Grid.Col>
          </Grid.Row>

          {company && company.membership !== MembershipTypeEnum.Admin && (
            <Grid.Row>
              <Grid.Col span={4}>
                <Dropdown
                  id="membership"
                  label={t("common.membershipType")}
                  options={membershipTypeOptions}
                  onChange={onChangeMembershipType}
                  value={selectedMembershipType}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="sm" strong={true} display="technical" tt="uppercase">
                {t("dashboard.invoice")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <TextField
                id="invoicemail"
                label={t("common.invoiceemail")}
                value={get(company, "invoiceemail", "")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ invoiceemail: e.target.value })}
                errormessage={validationErrors?.invoiceemail}
                disabled={!isAdmin}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Space height="25px" />
              <CheckBox
                id="prefer-invoice"
                label={t("settings.preferInvoice")}
                checked={get(company, "alwaysInvoice", false)}
                onChange={(e: any) => onChange({ alwaysInvoice: e.checked })}
                disabled={!isAdmin}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      {isAdmin && (
        <SettingsFormCTAs>
          <CTA size="lg" id="save" intent="primary" onClick={saveHandler}>
            {t("common.save")}
          </CTA>
          <Spinner loading={loadingSave} inline={true} />
        </SettingsFormCTAs>
      )}

      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Group justify="space-between">
                <Text size="md" strong={true} display="technical" tt="uppercase">
                  {t("common.address")}
                </Text>
                {isAdmin && (
                  <CTA id="add-address" intent="secondary" onClick={openAddAddressHandler}>
                    {t("common.newAddress")}
                  </CTA>
                )}
              </Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              {company && (
                <CompanyAddressTable
                  company={company}
                  isAdmin={isAdmin}
                  openEditAddressHandler={openEditAddressHandler}
                  asyncDeleteAddress={asyncDeleteAddress}
                />
              )}
              {messageBarMessage ? <MessageBar message={messageBarMessage} type={messageBarType}></MessageBar> : null}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Group justify="space-between">
                <Text size="md" strong={true} display="technical" tt="uppercase">
                  {t("settings.bankDetails")}
                </Text>
                {isAdmin && (
                  <CTA id="add-konto" intent="secondary" onClick={openAddBankAccountHandler}>
                    {t("settings.newAccount")}
                  </CTA>
                )}
              </Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              {company && (
                <BankAccountTable
                  company={company}
                  isAdmin={isAdmin}
                  openEditBankAccountHandler={openEditBankAccountHandler}
                  asyncDeleteBankAccount={asyncDeleteBankAccount}
                />
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>

      {/* ADD/EDIT ADDRESS */}
      <Modal
        open={isAddEditAddressOpen}
        onClose={() => closeEditAddress()}
        headerText={isNewAddress ? t("settings.addAddress") : t("settings.editAddress")}
      >
        <ModalContent>
          <Grid>
            <Grid.Row>
              <Grid.Col span={6}>
                <Dropdown
                  id="add-address-addresstype"
                  label={t("common.addressType")}
                  options={addressTypeOptions}
                  onChange={(e: any) => setSelectedAddressType(e)}
                  value={selectedAddressType}
                  errormessage={validationErrorsAddress.addressType}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={12}>
                <TextField
                  id="add-address-address"
                  label={t("common.address")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
                  value={address}
                  errormessage={validationErrorsAddress.address}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={2}>
                <TextField
                  id="add-address-postCode"
                  label={t("common.postCode")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPostCode(e.target.value)}
                  value={postCode}
                  errormessage={validationErrorsAddress.postCode}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  id="add-address-place"
                  label={t("common.place")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPlace(e.target.value)}
                  value={place}
                  errormessage={validationErrorsAddress.place}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Dropdown
                  id="add-address-country"
                  label={t("common.country")}
                  options={countryOptions}
                  onChange={(e: any) => setSelectedCountry(e)}
                  value={selectedCountry}
                  errormessage={validationErrorsAddress.country}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
          {missingFields.length > 0 && <MessageBar type={MessageBar.type.Negative} message={`${missingFields}`} />}
        </ModalContent>
        <ModalCTAs>
          <CTA id="add-address-cancel" intent="secondary" size="lg" onClick={() => closeEditAddress()}>
            {t("common.cancel")}
          </CTA>
          <CTA id="add-address-save" intent="primary" size="lg" onClick={addEditAddressHandler}>
            {t("common.save")}
          </CTA>
        </ModalCTAs>
      </Modal>

      {/* ADD/EDIT BANK ACCOUNTS */}
      <Modal
        open={isAddEditBankAccountOpen}
        onClose={() => closeEditBankAccount()}
        headerText={isNewBankAccount ? t("settings.addBankAcc") : t("settings.editBankAcc")}
      >
        <ModalContent>
          <Grid>
            <Grid.Row>
              <Grid.Col span={6}>
                <TextField
                  id="add-bankAccount-accountName"
                  label={t("settings.bankAccountName")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setBankAccountName(e.target.value)}
                  value={bankAccountName}
                  errormessage={validationErrorsBankAccount.name}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={6}>
                <TextField
                  id="add-bankAccount-accountNr"
                  label={t("settings.bankAccountNr")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setBankAccountNr(e.target.value)}
                  value={bankAccountNr}
                  errormessage={validationErrorsBankAccount.accountNo}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextField
                  id="add-bankAccount-iban"
                  label={"IBAN"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setBankAccountIban(e.target.value)}
                  value={bankAccountIban}
                  errormessage={validationErrorsBankAccount.iban}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ModalContent>
        <ModalCTAs>
          <CTA id="add-bankAccount-cancel" size="lg" intent="secondary" onClick={() => closeEditBankAccount()}>
            {t("common.cancel")}
          </CTA>
          <CTA id="add-bankAccount-save" size="lg" intent="primary" onClick={addEditBankAccountHandler}>
            {t("common.save")}
          </CTA>
        </ModalCTAs>
      </Modal>
    </>
  );
}
