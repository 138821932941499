import { BoxType } from "@/types";
import * as z from "zod";

export const CreateOrderFromContractSchema = z
  .object({
    amount: z
      .number({
        invalid_type_error: "amountRequired",
      })
      .min(1, "amountRequired"),
    unit: z.object(
      {
        value: z.number(),
        label: z.string(),
      },
      { required_error: "unitRequired" }
    ),
    boxtype: z.object(
      {
        value: z.number(),
        label: z.string(),
      },
      { required_error: "boxtypeRequired" }
    ),
    differentAddress: z.boolean().optional(),
    address: z.string().optional().optional(),
    postCode: z.string().optional(),
    countryId: z.object({ value: z.number(), label: z.string() }).optional(),
    place: z.string().optional(),
  })
  .superRefine(({ address, postCode, countryId, place, differentAddress }, refinementContext) => {
    if (differentAddress && !address) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "addressRequired",
        path: ["address"],
      });
    }
    if (differentAddress && !postCode) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "postCodeRequired",
        path: ["postCode"],
      });
    }
    if (differentAddress && !countryId) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "countryRequired",
        path: ["countryId"],
      });
    }
    if (differentAddress && !place) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "placeRequired",
        path: ["place"],
      });
    }
  });

export type CreateOrderFromContractInput = z.infer<typeof CreateOrderFromContractSchema>;
