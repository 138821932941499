import React, { FC, PropsWithChildren, useState, useEffect } from "react";
import styles from "./styles.module.css";
import Text from "@/components/_Reviver/atoms/Text";

interface Props {}

const Footer: FC<Props> = ({}) => {
  return (
    <Wrap>
      <Column>
        <address>
          <Text size="sm" color="primary" span={true}>
            Reviver AS
          </Text>
          <br />
          <Text size="sm" color="secondary">
            Dronningensgata 2a
            <br />
            4610 Kristiansand
            <br />
            Norway
          </Text>
        </address>
      </Column>
      <Column>
        <Text size="sm">
          <a href="mailto:info@reviver.no">info@reviver.no</a>
        </Text>
        <ul className={styles.linkList}>
          <li>
            <Text size="sm">
              <a href="https://www.reviver.no/company/legal" target="_blank">
                Privacy policy
              </a>
            </Text>
          </li>
          <li>
            <Text size="sm">
              <CookiePreferencesButton />
            </Text>
          </li>
        </ul>
      </Column>
    </Wrap>
  );
};

const Wrap: FC<PropsWithChildren> = (props) => {
  return (
    <div className={styles.footerRoot}>
      <div className={styles.inner}>{props.children}</div>
    </div>
  );
};

const Column: FC<PropsWithChildren> = (props) => {
  return <div className={styles.column}>{props.children}</div>;
};

const CookiePreferencesButton = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const checkCookieScript = () => {
      if (typeof window.cookieconsent === "object") {
        setIsLoaded(true);
      } else {
        console.warn("Cookie preferences script not loaded yet. Retrying...");
        setTimeout(checkCookieScript, 500);
      }
    };

    checkCookieScript();
  }, []);

  const openCookiePreferences = () => {
    if (typeof window.cookieconsent === "object") {
      window.cookieconsent.openPreferencesCenter();
    } else {
      console.error("Cookie preferences modal function is not available.");
    }
  };

  return (
    <button onClick={openCookiePreferences} id="open_preferences_center" className="cookie-button">
      Cookie Preferences
    </button>
  );
};

export default Footer;
