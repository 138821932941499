import { axios, APIBASE_REVIVERAPPLICATION } from "./ApiConfig";
import { Condition, CreateOrderDropdownValues, Type, BoxType, CompanyStatistics, CreateOrderFromContract } from "@/types";
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

export const getOrdersForCompany = (companyId: any, includeInactive: any, pageNumber: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getOrdersForCompany?`, {
    params: {
      companyId,
      includeInactive,
      pageNumber,
    },
  });

export const getMyOrderList = (companyId: any, includeInactive: any, pageNumber: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getMyOrderList?`, {
    params: {
      companyId,
      includeInactive,
      pageNumber,
    },
  });

export const getOrder = (orderId: any) => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}`);

export const getOrderByTransactionId = (transactionId: any, companyId: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/getOrderByTransactionAndCompanyId/${transactionId}/${companyId}`);

export const postFilteredOrders = async (filter: any) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/filteredOrders`, filter);

export const getFilters = async (currency: any, unit: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/filters?currency=${currency}&unit=${unit}`);

export const getCreateOrderFromOrder = async (orderId: any, companyId: any, userId: any, priceToPay: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/createOrderFromOrder`, {
    params: {
      orderId,
      companyId,
      userId,
      priceToPay,
    },
  });

export const postCreateOrder = async (order: any) => axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/create`, order);

export const postCreateOrderFromContract = async (orderData: CreateOrderFromContract) => axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/createOrderFromContract`, orderData);

export const deleteOrder = async (orderId: any) => axios.delete(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/delete`);

export const getCreateOrderValues = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/values`);

export const useGetCreateOrderValues = (options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<CreateOrderDropdownValues>({
    queryKey: ['getCreateOrderValues'],
    queryFn: async () => {
      const result = await getCreateOrderValues();
      return result.data;
    },
    ...queryOptions,
  });
};

export const postReleaseReservation = async (orderId: any) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/releaseReservation/${orderId}`);

export const getValidConditions = async (salesItemId: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/validConditions/${salesItemId}`);

export const useGetValidConditions = (salesItemId: any, options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<Condition[]>({
    queryKey: ['getValidConditions', salesItemId],
    queryFn: async () => {
      const result = await getValidConditions(salesItemId);
      return result.data;
    },
    ...queryOptions,
  });
};

export const getValidTypes = async (salesItemId: any, conditionId: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/validConditions/${salesItemId}/validTypes/${conditionId}`);

export const useGetValidTypes = (salesItemId: any, conditionId: any, options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<Type[]>({
    queryKey: ['getValidTypes', salesItemId, conditionId],
    queryFn: async () => {
      const result = await getValidTypes(salesItemId, conditionId);
      return result.data;
    },
    ...queryOptions,
  });
}

export const createPayment = async (orderId: any) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/checkout/${orderId}`);

export const completePayment = async (orderId: any) =>
  axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/checkout/${orderId}/complete`);

export const getOrderAmounts = async (orderId: any) =>
  axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/amounts`);

export const getStatistics = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/admin/orderStatistics`);

export const postInvoice = async (orderId: any, taskId: any) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/invoice/${orderId}`, "", taskConfig);
};

export const postHandleTransport = async (orderId: any, transportCompaniesIds: any, taskId: any) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(
    `${APIBASE_REVIVERAPPLICATION}api/orders/handleTransport`,
    {
      orderId,
      transportCompaniesIds,
    },
    taskConfig
  );
};

export const postUpdateOrder = async (orderId: any, order: any) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/update`, order);

export const postPromoteOrder = async (orderId: any) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/promote/${orderId}`);

export const getStaleTransactions = async () =>
  await axios.get(`${APIBASE_REVIVERAPPLICATION}api/admin/staleTransactions`);

export const getBoxtypeValues = async () => axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/create/values/boxtypes`);

export const useGetBoxtypeValues = (options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<BoxType[]>({
    queryKey: ['getBoxtypeValues'],
    queryFn: async () => {
      const result = await getBoxtypeValues();
      return result.data.boxtypes;
    },
    ...queryOptions,
  });
}

export const postCalculateTransportPrice = async (
  orderId: any,
  postcode: any,
  country: any,
  place: any,
  currentCompanyId: any,
  amount: any,
  numberOfBoxes: any,
  boxtypeId: any
) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/calculateTransportPrice`, {
    orderId,
    postcode,
    country,
    place,
    currentCompanyId,
    amount,
    numberOfBoxes,
    boxtypeId,
  });

export const postCalculateTransportPrices = async (
  orderId: any,
  address: any,
  postCode: any,
  place: any,
  countryCodeIso3: any,
  countryCodeIso2: any,
  isEu: any
) =>
  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/calculateTransportPrices`, {
    orderId,
    address: {
      address,
      postCode,
      place,
      countryCodeIso3,
      countryCodeIso2,
      isEu,
    },
  });

export const getSalesItemById = async (salesItemId: any) =>
  await axios.get(`${APIBASE_REVIVERAPPLICATION}api/orders/salesitem/${salesItemId}`);

export const postFinalAmount = async (orderId: any, amount: any, taskId: any) => {
  let taskConfig = {
    headers: {
      TaskId: taskId,
    },
  };

  await axios.post(`${APIBASE_REVIVERAPPLICATION}api/orders/${orderId}/setFinalAmount/${amount}`, "", taskConfig);
};

const getStatisticsForCompanies = async (from: Date, to: Date, companyIds: number[], salesItemIds: number[]) =>
  await axios.get(`${APIBASE_REVIVERAPPLICATION}api/statistics/company`, {
    params: {
      from,
      to,
      companyIds,
      salesItemIds
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

export const useGetStatisticsForCompanies = (from: Date, to: Date, companyIds: number[], salesItemIds: number[], options?: any) => {
  const queryOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery<CompanyStatistics[]>({
    queryKey: ['getStatisticsForCompanies', from, to, companyIds, salesItemIds],
    queryFn: async () => {
      const result = await getStatisticsForCompanies(from, to, companyIds, salesItemIds);
      return result.data;
    },
    ...queryOptions,
  });
}