import { forwardRef } from "react";
import PropTypes from "prop-types";
import SVGAsset from "../SVGAsset/SVGAsset";

import "./Checkbox.css";

function checkbox(props, ref) {
  const id = Date.now() + "" + Math.random();
  return (
    <label className="checkbox">
      <span className="checkbox-input">
        <input
          {...props}
          checked={props.checked}
          type="checkbox"
          id={`id-${id}`}
          ref={ref}
          onChange={(e) => {
            const input = e.target;
            e.checked = input.checked;
            if (props.onChange) {
              props.onChange(e);
            }
          }}
        />
        <span className="checkbox-control">
          <SVGAsset className="svgAsset" name="white-checkmark"></SVGAsset>
        </span>
      </span>
      <label htmlFor={`id-${id}`}>{props.label}</label>
    </label>
  );
}

const forwardedCheckfield = forwardRef(checkbox);

checkbox.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default forwardedCheckfield;
