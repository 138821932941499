import { forwardRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetAllCompanies } from "@/services/Company";
import { DropdownOption } from "@/types";
import { ContractDetailsInput, ContractDetailsSchema } from "../validation/contract-details";
import Grid from "@/components/Grid/Grid";
import Dropdown from "@/components/Dropdown/Dropdown";
import TextField from "@/components/TextField/TextField";
import RadioButtonGroup from "@/components/_Reviver/molecules/RadioButtonGroup";
import Divider from "@/components/_Reviver/atoms/Divider";
import UserContext from "@/contexts/UserContext";
import ContractTypeEnum from "@/constants/enums/ContractTypeEnum";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import DatePicker from "@/components/DatePicker/DatePicker";

interface Props {
  onValidationSuccess: (data: any) => void;
  onChangeValues: (changed: any) => void;
  values: any;
}

const ContractDetails = forwardRef<HTMLFormElement, Props>(({ onValidationSuccess, onChangeValues, values }, ref) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [existingNetwork, setExistingNetwork] = useState<DropdownOption[]>([]);
  const { data: companiesTradingPartners } = useGetAllCompanies();

  useEffect(() => {
    setExistingNetwork(
      companiesTradingPartners
        ? companiesTradingPartners
            .filter((company) => company.companyType === "TRADER" && company.id != currentCompany.companyId)
            .map((company) => {
              return { value: company.id, label: company.companyName };
            })
        : []
    );
  }, [companiesTradingPartners]);

  const contractTradeTypeOptions = [
    { value: ContractTradeTypeEnum.SELL, label: t("contracts.sale") },
    { value: ContractTradeTypeEnum.BUY, label: t("contracts.purchase") },
  ];

  const contractTradeByOptions = [
    { value: ContractTypeEnum.FIXED_AMOUNT, label: t("common.amount") },
    { value: ContractTypeEnum.FIXED_EXPIRY, label: t("common.date") },
  ];

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ContractDetailsInput>({
    resolver: zodResolver(ContractDetailsSchema),
  });

  const onSubmit = (data: ContractDetailsInput) => {
    onValidationSuccess(data);
  };

  const { onChange: onChangeName, ...registerName } = register("name");
  const { onChange: onChangeDescription, ...registerDescription } = register("description");

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Row>
          <Grid.Col span={12}>
            <TextField
              id="create-contract-name"
              label={t("contracts.contractName")}
              placeholder={t("contracts.contractName") + "..."}
              value={values.name}
              errormessage={errors.name?.message}
              onChange={(e: any) => {
                onChangeName(e);
                onChangeValues({
                  name: e.target.value,
                });
              }}
              {...registerName}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <TextField
              id="create-contract-description"
              label={t("contracts.contractDescription")}
              placeholder={t("contracts.contractDescription") + "..."}
              value={values.description}
              errormessage={errors.description?.message}
              onChange={(e: any) => {
                onChangeDescription(e);
                onChangeValues({
                  description: e.target.value,
                });
              }}
              {...registerDescription}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Controller
              control={control}
              name="contractDate"
              defaultValue={values.contractDate}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  id="create-order-contractDate"
                  label={t("contracts.contractDate")}
                  value={value}
                  onChange={onChange}
                  //@ts-ignore - TODO: fix this, need a typeguard or something. It's guarenteed that contractEndDate is present with the === FIXED_EXPIRY check above.
                  errormessage={errors.contractDate?.message}
                />
              )}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <RadioButtonGroup
              title={t("contracts.contractType")}
              options={contractTradeTypeOptions}
              defaultValue={values.contractTradeType}
              onValueChange={(value: string) => {
                onChangeValues(
                  value === ContractTradeTypeEnum.BUY
                    ? {
                        contractSellers: [],
                        contractTradeType: value,
                      }
                    : {
                        contractBuyers: [],
                        contractTradeType: value,
                      }
                );
                setValue("tradingPartner", null);
              }}
              inlineTitle={true}
              name={"contractType"}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Controller
              control={control}
              name="tradingPartner"
              defaultValue={
                values.contractTradeType === ContractTradeTypeEnum.BUY
                  ? existingNetwork.find((x) => x.value === values.contractBuyers[0])
                  : existingNetwork.find((x) => x.value === values.contractSellers[0])
              }
              render={({ field: { onChange: tradingPartnerOnChange, value: tradingPartnerValue } }) => (
                <Dropdown
                  id="create-contract-trading-partner"
                  name="trading-partner"
                  label={t("contracts.tradingPartner")}
                  options={existingNetwork}
                  value={tradingPartnerValue}
                  errormessage={errors.tradingPartner?.message}
                  onChange={(e) => {
                    tradingPartnerOnChange(e);
                    onChangeValues(
                      values.contractTradeType === ContractTradeTypeEnum.BUY
                        ? {
                            contractBuyers: [e.value],
                          }
                        : {
                            contractSellers: [e.value],
                          }
                    );
                  }}
                />
              )}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Controller
              control={control}
              name="contractType"
              defaultValue={contractTradeByOptions.find((x) => x.value === values.contractType)?.value}
              render={({ field: { onChange, value: formValue } }) => (
                <RadioButtonGroup
                  title={t("contracts.tradeBy")}
                  options={contractTradeByOptions}
                  defaultValue={formValue}
                  onValueChange={(value: string) => {
                    onChange(value);
                    onChangeValues({
                      contractType: value,
                    });
                  }}
                  inlineTitle={true}
                  name={"tradeBy"}
                />
              )}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </form>
  );
});

export default ContractDetails;
