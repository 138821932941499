import { FC, ReactNode, forwardRef, useContext } from "react";
import { getContractTradeType, contractsUIConfig } from "@/utils/Contracts";
import { useTranslation } from "react-i18next";
import StatusTable from ".";
import SVGAsset from "@/components/SVGAsset/SVGAsset";
import { Contract } from "@/types";
import UserContext from "@/contexts/UserContext";
import ContractTradeTypeEnum from "@/constants/enums/ContractTradeTypeEnum";
import moment from "moment";

interface Props {
  contract: Contract;
}

interface Props {}

const ContractsTable: FC<Props> = ({ contract }: Props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const contractTradeType = getContractTradeType(contract, currentCompany);
  const { tableIconName } = contractsUIConfig(contractTradeType);

  const ordersOnContract =
    contractTradeType === ContractTradeTypeEnum.SELL ? contract.salesOrdersOnContract : contract.buyOrdersOnContract;

  const tableHeaders: string[] = ["", "Dato", "Produktet", "Mengde", "Pris", "Status", "Fullført"];

  const tableRows: ReactNode[][] = ordersOnContract.map((order) => {
    return [
      <SVGAsset name={tableIconName} />,
      `${moment(order.validFrom).format("DD.MM.YYYY")}`,
      t("common.plastic." + order.salesItem.salesItem),
      `${order.amount} ${order.unit.unit}`,
      `${order.price?.NOK} ${order.orderCurrency}/${order.priceUnit?.unit}`,
      `${contract.contractStatus}`,
      `...?`,
    ];
  });

  return (
    <StatusTable
      tableHeaders={tableHeaders}
      tableRows={tableRows}
      statusIndex={5}
      accordionTriggerContentIndices={[0, 1]}
      accordionBorderStyle="sell"
    />
  );
};

export default ContractsTable;
